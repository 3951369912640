var deletion_url;
var deletion_class = 'delete_item';
var deletion_id_attribute = 'item_id';
var deletion_text;

function init_deletion(deletion_options){
    // add settings
    deletion_url = deletion_options.url;
    deletion_text = deletion_options.text;
    if(deletion_options.class != null){
        deletion_class = deletion_options.class;
    }
    if(deletion_options.deletion_id_attribute != null){
        deletion_id_attribute = deletion_options.deletion_id_attribute;
    }

    // add form to body
    $("body").append('<form method="post" id="deletion_form"></form>');
    // create action listener
    $("." + deletion_class).click(deletion_trigger);
}
function deletion_trigger(){
    var confirmbox = confirm(deletion_text);
    if(confirmbox){
        var to_url = deletion_url.replace(":id",$(this).attr(deletion_id_attribute));
        $("#deletion_form").attr('action',to_url);
        $("#deletion_form").submit();
    }
}