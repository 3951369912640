jQuery.fn.extend({
    toggleText: function(stateOne, stateTwo) {
        return this.each(function() {
            stateTwo = stateTwo || '';
            $(this).text() !== stateTwo && stateOne ? $(this).text(stateTwo)
                                                    : $(this).text(stateOne);
        });  
    }
});

function nl2br (str, is_xhtml) {   
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';    
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1'+ breakTag +'$2');
}