// Remove item
$.fn.owlRemoveItem = function(num) 
{
	// Get the owl carousel data
	var owl_data = $(this).data('owlCarousel');
	
	// Filter the data items
	owl_data._items = $.map(owl_data._items, function(data, index) {
		if(index != num) return data;
	})

	// Remove the item
	$(this).find('.owl-item').eq(num).remove();
}

// Filter the items
$.fn.owlFilter = function(data, callback) 
{
	// Variables..
	var owl = this;
	var owl_data = $(owl).data('owlCarousel');
	var	$elemCopy = $('<div>').css('display', 'none');
	
	/* check attr owl-clone exist */
	if (typeof($(owl).data('owl-clone')) == 'undefined') 
	{
		$(owl).find('.owl-item:not(.cloned)').clone().appendTo($elemCopy);
		$(owl).data('owl-clone', $elemCopy);
	}
	else 
	{
		$elemCopy = $(owl).data('owl-clone');
	}
	
	/* clear content */
	owl.trigger('replace.owl.carousel', ['<div/>']);
	
	if ($elemCopy)
	{
		// Loop through the names of each item
		$elemCopy.find('.name').find('span').each(function(index, ele)
		{
			$(ele).removeHighlight();

			// Get the name
			let name = $(this).html();

			// If the name matches the input values data
			if (name.toUpperCase().indexOf(data) > -1)
			{
				// Highlight				
				$(ele).highlight(data);

				// Add the item
				owl.trigger('add.owl.carousel', [$(this).closest('.item').parent().clone()]);
			}
		});
	}

	/* remove item empty when clear */
	owl.owlRemoveItem(0);
	owl.trigger('refresh.owl.carousel').trigger('to.owl.carousel', [0]);

	// callback
	if (callback) callback.call(this, owl);
}