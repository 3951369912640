var default_form_label_singular = 'formulier';
var default_form_label_plural = 'formulieren';
var url_delete_formsession = "/formsessions/:id/delete";
var url_formsession_updates = "/formsessions/:id/updates/?from=:timestamp";
var url_toggle_livepreview = "/formsessions/:id/livepreview";
var url_formsession = "/formsessions/:id/";
var live_preview_interval_time = 4000; // milliseconds
var live_preview_highlight_time = 5000;
var default_error = "Er ging iets mis met het verwerken van de opdracht. Probeer het nogmaals of neem contact op met de beheerder";

// live preview
var live_preview_last_update = null;
var live_preview_interval = null;
$(window).ready(function(){
    $('.action_delete_formsession').click(function()
    {
    	$obj = $(this);
        if (typeof url_index_formsessions === 'undefined')
        {
            throw 'url_index_formsessions not set';
        }

        Swal.fire(
        {
            title: get_formlabel_singular() + ' verwijderen',
            text: 'Weet u zeker dat u dit ' + get_formlabel_singular() + ' wilt verwijderen?',
            showCancelButton: true,
            confirmButtonText: 'Verwijder',
            cancelButtonText: 'Annuleer',
            reverseButtons: true,
        }).then(function(result)
        {
            if (result.value)
            {
                showLoader('Uw ' + get_formlabel_singular() + ' wordt verwijderd.');

                var params = {
                    _token: csrf_token,
                };

                var url = url_delete_formsession.replace(':id', $obj.attr('formsession_id'));
                $.post(url, params).done(function(data) 
                {
                    hideLoader();
                    if (!data.success)
                    {
                        alert(default_error);
                    }
                    else
                    {
                        if (url_index_formsessions.indexOf('observation') != -1)
                        {
                            window.location = url_index_observation;
                        }
                        else if (url_index_formsessions.indexOf('evaluation') != -1)
                        {
                            window.location = url_index_evaluation;
                        }
                        else if (url_index_formsessions.indexOf('rating') != -1)
                        {
                            window.location = url_index_rating;
                        }
                        else
                        {
                            window.location = url_index_formsessions;
                        }
                    }
                }).fail(function()
                {
                    hideLoader();
                    alert(default_error);
                });
            }
        });
    });

    /**
     * Toggle Live Preview
     */
    $('.toggle_live_preview').click(function(){
        showSmallLoader();

        var params = {
            _token: csrf_token,
        };
        var url = url_toggle_livepreview.replace(':id',formsession_id);
        $.post(url,params).done(function(data) {

            hideSmallLoader();
            $('.toggle_live_preview').each(function(){
                if(data.enabled){
                    $(this).removeClass('btn-warning');
                    $(this).addClass('btn-success');
                    $(this).html('<span class="glyphicon glyphicon-eye-close"></span> Stop meekijken');
                }
                else{
                    $(this).addClass('btn-warning');
                    $(this).removeClass('btn-success');
                    $(this).html('<span class="glyphicon glyphicon-eye-open"></span> Laten meekijken');
                }
            });
        }).fail(function() {
            hideLoader();
            alert(default_error);
        });

    });

    if(typeof livePreviewEnabled !== 'undefined'){
        if(livePreviewEnabled) {
            set_formsession_last_update_time();
            live_preview_interval = setInterval(get_formsession_updates,live_preview_interval_time);
        }
    }

    $('.action_confirm_create').click(function(evt){
    	evt.preventDefault();
    	
        Swal.fire(
        {
            title: get_formlabel_singular() + ' definitief maken', 
            text: 'Weet u zeker dat u dit ' + get_formlabel_singular() + ' definitief wilt maken?',
            showCancelButton: true,
            confirmButtonText: 'Maak definitief',
            cancelButtonText: 'Annuleer',
            reverseButtons: true,
        }).then(function(result)
        {
            if (result.value)
            {
                var form = $(this).parents('form');
                form.find('input[name="definitive"]').val(true);
                form.submit();
            }
        }.bind($(this)));     
    });

    $('.action_edit_label').click(function()
    {
        if (typeof url_save_label === 'undefined')
        {
            throw 'url_save_label not set';
        }

        var label_value = $('#formsession_label_input').val();

        Swal.fire(
        {
            title: label_value ? 'Naam wijzigen' : 'Naam toevoegen',
            text: label_value ? 'Wijzig de naam van dit formulier' : 'Vul een naam in om aan dit formulier toe te voegen' ,
            input: 'text',
            inputValue: label_value,
            showCancelButton: true,
            confirmButtonText: label_value ? 'Wijzig naam' : 'Naam toevoegen',
            cancelButtonText: 'Annuleer',
            reverseButtons: true,
            inputValidator: function (value)
            {
                if (!value)
                {
                    return 'U dient een naam in te vullen.'
                }
                else
                {
                    $('#formsession_label span:first-child').html(value);
                    $('#formsession_label_input').val(value);
                    $('#formsession_label').show();

                    $.post(url_save_label,
                    {
                        _token: csrf_token,
                        label: value
                    });
                }
            }
        });
    });

    /*
        Show/hide the coop enabled divs
     */
    $('.show_coop_enabled_by').click(function(){
        if($('.coop_enabled_by').first().is(':visible')){
            $('.coop_enabled_by').hide();
            $('.show_coop_enabled_by').html('Toon herkomst');
            $('.item-options').css('width', '');
            
            $(this).parents('form').removeClass('coop_on');
            
            $('.show_coop_enabled_by').parents('form').find('.print').attr('href', $('.show_coop_enabled_by').parents('form').find('.print').attr('href').replace('?herkomst=1', ''));
            
            if($('.touch-check').is(':visible')) {
            	$('.appendix-coop .for_user').css('color', '#000');
            	$('.appendix-coop .by_user').css('color', '#000');
            	$('.dropdown_button ~ ul').attr('data-view', 'coop');
            } else {
            	$('.appendix-coop').hide();
	            $('.appendix').show();
	            $('.dropdown_button ~ ul').attr('data-view', 'normal');
            }
        }
        else{
            $('.coop_enabled_by').show();
            $('.show_coop_enabled_by').html('Verberg herkomst');
            $('.item-options').css('width', 'calc(100% - 170px)');
            
            $(this).parents('form').addClass('coop_on');
            
            $('.show_coop_enabled_by').parents('form').find('.print').attr('href', $('.show_coop_enabled_by').parents('form').find('.print').attr('href')  + '?herkomst=1', '');
            
            if($('.touch-check').is(':visible')) {
            	$('.appendix-coop .for_user').css('color', '#06b200');
            	$('.appendix-coop .by_user').css('color', '#0036ff');
            	$('.dropdown_button ~ ul').attr('data-view', 'coop');
            } else {
            	$('.appendix-coop').show();
	            $('.appendix').hide();
	            $('.dropdown_button ~ ul').attr('data-view', 'coop');
            }
        }
    });

    /*
        make new formsesion (observation) from the showed formsesion
     */
    $('.action_make_observation_from_formsession').click(function(){
        var check = $('.checkbox_formsessionitem_ids:checked').length;
        if(!check)
        {
            Swal.fire({
                text: 'U heeft geen items geselecteerd. Dit kunt u doen door de hokjes voor de items te selecteren.'
            });
        }
        else
        {
            var formsession_id = $(this).attr('formsession_id');
            if (typeof formsession_id === typeof undefined || formsession_id === false) {
                formsession_id = '';
            }
            var txt = 'Weet u zeker dat u een observatieformulier wilt maken op basis van de geselecteerde items?';
            var btn_txt = 'Maak observatieformulier';

            if (formsession_id)
            {
                txt = 'Weet u zeker dat u de geselecteerde items aan de bestaande observatieformulier wilt toevoegen?';
                btn_txt = 'Voeg items toe';
            }

            Swal.fire(
            {
                text: txt,
                showCancelButton: true,
                confirmButtonText: btn_txt,
                cancelButtonText: 'Annuleer',
                reverseButtons: true,
            }).then(function(result)
            {
                if (result.value)
                {
                    var _url = url_new_by_session + '&create_from_formsession_id=' + formsession_id;
                    $('#form_formsession').attr('action', _url);
                    $('#form_formsession').submit();
                }
            });
        }
    });

    $('.finish_formsession_fase').click(function(event)
    {
        event.preventDefault();
        var form = $(this).parents('form');

        Swal.fire(
        {
            title: 'Fase definitief maken',
            text: 'Weet u zeker dat u deze fase definitief wilt maken?',
            showCancelButton: true,
            confirmButtonText: 'Maak definitief',
            cancelButtonText: 'Annuleer',
            reverseButtons: true,
        }).then(function(result)
        {
            if (result.value)
            {
                form.submit();
            }
        });
    });

    $('.action_finish_formsession, .action_finish_formsession_and_fill_by_user, .action_sign_formsession').hover(function()
    {
        $(this).find('.glyphicon-unchecked').removeClass('glyphicon-unchecked').addClass('glyphicon-check');
    },
    function()
    {
        $(this).find('.glyphicon-check').removeClass('glyphicon-check').addClass('glyphicon-unchecked');
    });

    $('.action_finish_formsession').click(function(e)
    {
        Swal.fire(
        {
            title: 'Fase definitief maken', 
            text: 'Weet u zeker dat u deze fase definitief wilt maken?',
            showCancelButton: true,
            confirmButtonText: 'Maak definitief',
            cancelButtonText: 'Annuleer',
            reverseButtons: true,
        }).then(function(result)
        {
            if (result.value) $('#form_formsession').submit();
        });
    });

    $('.action_finish_formsession_and_fill_by_user').click(function(e)
    {
        $('#fill_by_user').val(1);

        Swal.fire(
        {
            title: 'Aanbieden voor \'Directe feedback\'', 
            text: 'Weet u zeker dat u het formulier wilt aanbieden voor \'Directe feedback\'?',
            showCancelButton: true,
            confirmButtonText: 'Aanbieden',
            cancelButtonText: 'Annuleer',
            reverseButtons: true,
        }).then(function(result)
        {
            if (result.value) $('#form_formsession').submit();
        });
    });
    $('.answerFieldButton').click(function(){
        var formsessionfield_id = $(this).attr('formsessionfield_id');
        var value = null;

        if($(this).hasClass('active'))
        {
            $(this).removeClass('active');
            $('#formsessionitem_' + $(this).attr('formsessionitem_id')).val(null);
        }
        else{
            $(this).addClass('active');

            var value = $(this).attr('formresultgroup_id');
            $('#formsessionfield_' + $(this).attr('formsessionfield_id')).val(value);

            //uncheck other answers
            $('.fieldanswers_of_' + $(this).attr('formsessionfield_id')).each(function(){
                if($(this).attr('formresultgroup_id') != value)
                {
                    $(this).removeClass('active');
                }
            });
        }

        saveFormsessionfield(formsessionfield_id,{
            value: value
        });
    });
    
    $('input[id="link-url"]').on('blur', function(){
       var string = $(this).val();
       if (!string.match(/^https?:/) && string.length) {
         string = "http://" + string;
          $(this).val(string);
       }
     });
     
     $('textarea').not('#popupBoxNoteContent').each(function () {
     	var scrollHeight = (this.scrollHeight);
     	if(scrollHeight < 68) scrollHeight = 68;
	  	this.setAttribute('style', 'height:' + scrollHeight + 'px;overflow-y:hidden;resize:none;padding-right: 50px;');
	}).on('input', function () {
	  // compute the height difference which is caused by border and outline
        var outerHeight = parseInt(this.style.height, 10);
        var diff = outerHeight - this.clientHeight;

        // set the height to 0 in case of it has to be shrinked
        this.style.height = 0;

        // set the correct height
        // el.scrollHeight is the full height of the content, not just the visible part
        this.style.height = Math.max(68, this.scrollHeight + diff) + 'px';
    
	});
	
	$('.add_own_point, #link-url').on('keyup keypress', function(e) {
	  var keyCode = e.keyCode || e.which;
	  if (keyCode === 13) { 
	    e.preventDefault();
	    return false;
	  }
	});
});


/**
 * Return formlabel singular
 * @returns {*}
 */
function get_formlabel_singular(){
    if(typeof form_label_singular !== 'undefined'){
        return default_form_label_singular
    }
    else{
        return form_label_singular;
    }
}
/**
 * Return formlabel plural
 * @returns {*}
 */
function get_formlabel_plural(){
    if(typeof form_label_plural !== 'undefined'){
        return default_form_label_plural
    }
    else{
        return form_label_plural;
    }
}
/**
 * This function starts an ajax request to get the latest formsession updates
 */
function get_formsession_updates(){
    var url = url_formsession_updates.replace(':id',formsession_id).replace(':timestamp',live_preview_last_update);
    var jqxhr = $.getJSON( url)
        .done(parse_formsession_updates)
        .fail(function() {
            alert(default_error);
        });
}
/**
 * This function parses the response data from the formsession updates
 * @param data
 */
function parse_formsession_updates(data){
    if(!data.enabled){
        clearInterval(live_preview_interval);
        window.location = url_formsession.replace(':id',formsession_id);
    }
    else{
    	//groups
    	for(i = 0; i < data.groups.length; i++){
    		var formsessionitem = data.groups[i];

            var formsessionitem_result_box = $('#formsessionitem_result_' + formsessionitem.formsessiongroup_id);
            
            if(formsessionitem.answered_formresultgroup_id) {
            	formsessionitem_result_box.find('.groupscore').show();
            	formsessionitem_result_box.find('.itemsscore').hide();
            } else {
            	formsessionitem_result_box.find('.groupscore').hide();
            	formsessionitem_result_box.find('.itemsscore').show();
            }
            
            var ul = formsessionitem_result_box.find('ul.groupscore ul.score');
            ul.find('ul > li').each(function(){
                var span = $(this).find('span').first();
                if($(this).attr('formsessionresultgroup_id') == formsessionitem.answered_formresultgroup_id)
                {
                    span.addClass('blauw');
                }
                else
                {
                    span.removeClass('blauw');
                }
            });
            
            if(formsessionitem_result_box.find('#div_comment_formsessiongroup_'+formsessionitem.formsessiongroup_id+' > .value').first().html() != formsessionitem.comments){
                formsessionitem_result_box.find('#div_comment_formsessiongroup_'+formsessionitem.formsessiongroup_id+' > .value').first().html(formsessionitem.comments);
            }
            
            if(!formsessionitem.comments){
                formsessionitem_result_box.find('#div_comment_formsessiongroup_'+formsessionitem.formsessiongroup_id).hide();
            }
            else{
                formsessionitem_result_box.find('#div_comment_formsessiongroup_'+formsessionitem.formsessiongroup_id).show();
            }
            
            // discussion_points
            if(formsessionitem_result_box.find('#div_discussionpoints_formsessiongroup_'+formsessionitem.formsessiongroup_id+' > .value').first().html() != formsessionitem.discussion_points){
                formsessionitem_result_box.find('#div_discussionpoints_formsessiongroup_'+formsessionitem.formsessiongroup_id+' > .value').first().html(formsessionitem.discussion_points);
            }
            
            if(!formsessionitem.discussion_points){
                formsessionitem_result_box.find('#div_discussionpoints_formsessiongroup_'+formsessionitem.formsessiongroup_id).hide();
            }
            else{
                formsessionitem_result_box.find('#div_discussionpoints_formsessiongroup_'+formsessionitem.formsessiongroup_id).show();
            }

           
            $('.appendixContent[data-id="'+formsessionitem.formsessiongroup_id+'"][data-type="group"]').replaceWith(formsessionitem.appendix);
            formsessionitem_result_box.effect("highlight", {}, live_preview_highlight_time);
    	}
    	
    	
        // items
        for(i = 0; i < data.items.length; i++){
            var formsessionitem = data.items[i];

            // formresultgroup
            if(form.result_layout == '4grades'){

                var isChanged = false;
                var ul = $('#list_resultgroup_formsessionitem_' + formsessionitem.formsessionitem_id);
                ul.find('li').each(function(){
                    var span = $(this).find('span').first();
                    if($(this).attr('formsessionresultgroup_id') == formsessionitem.answered_formresultgroup_id)
                    {
                        span.addClass('blauw');
                    }
                    else
                    {
                        span.removeClass('blauw');
                    }
                    isChanged = true;
                });

                if(isChanged) {
                    ul.parent().parent().parent().effect("highlight", {}, live_preview_highlight_time);
                }
            }
            else{
                //console.log('print answer');
            }

            // comments
            liveCommentBoxUpdate('#div_comment_formsessionitem_' + formsessionitem.formsessionitem_id,formsessionitem.comments);

            // discussion_points
            if(formsession.status == 'discuss'){
                liveCommentBoxUpdate('#div_discussionpoints_formsessionitem_' + formsessionitem.formsessionitem_id,formsessionitem.discussion_points);
            }
            if(formsessionitem.agreement)
            {
                liveCommentBoxUpdate('#div_agreement_formsessionitem_' + formsessionitem.formsessionitem_id,formsessionitem.agreement.content);
            }
            else
            {
                $('#div_agreement_formsessionitem_' + formsessionitem.formsessionitem_id).hide();
                $('#div_agreement_formsessionitem_' + formsessionitem.formsessionitem_id).find('.value').first().html('');
            }
            
            $('.appendixContent[data-id="'+formsessionitem.formsessionitem_id+'"][data-type="item"]').replaceWith(formsessionitem.appendix);
        }

        // fields
        for(i = 0; i < data.fields.length; i++) {
            var formsessionfield = data.fields[i];

            var formsessionfield_result_box = $('#formsessionfield_result_' + formsessionfield.formsessionfield_id);

            if(formsessionfield_result_box.find('.value').first().html() != formsessionfield.value){
                formsessionfield_result_box.find('.value').first().html(formsessionfield.value);
            }

            if(!formsessionfield.value){
            	//formsessionfield_result_box.hide();
            	//formsessionfield_result_box.find('.value').hide();
                //formsessionfield_result_box.find('.value').parent().hide();
                formsessionfield_result_box.find('#div_comment_formsessionfield_'+formsessionfield.formsessionfield_id).hide();
            }
            else{
            	//formsessionfield_result_box.show();
            	//formsessionfield_result_box.find('.value').show();
                formsessionfield_result_box.find('#div_comment_formsessionfield_'+formsessionfield.formsessionfield_id).show();
            }

            var formsessionfield_comment_box = formsessionfield_result_box.find('.discussion_points').first();
            formsessionfield_comment_box.find('.value').first().html(formsessionfield.discussion_points);
            if(formsessionfield.discussion_points){
                formsessionfield_comment_box.show();
                //formsessionfield_result_box.effect("highlight", {}, live_preview_highlight_time);
            }
            else{
                formsessionfield_comment_box.hide();
            }
            
            if(formsessionfield.agreement)
            {
                liveCommentBoxUpdate('#div_agreement_formsessionfield_' + formsessionfield.formsessionfield_id,formsessionfield.agreement.content);
            }
            else
            {
            	console.log('else');
                $('#div_agreement_formsessionfield_' + formsessionfield.formsessionfield_id).hide();
                $('#div_agreement_formsessionfield_' + formsessionfield.formsessionfield_id).find('.value').first().html('');
            }
            
            $('.appendixContent[data-id="'+formsessionfield.formsessionfield_id+'"][data-type="field"]').replaceWith(formsessionfield.appendix);
            formsessionfield_result_box.parent().effect("highlight", {}, live_preview_highlight_time);
        }
        
        if(data.noitems) {
        	var noitems = data.noitems;
        	var noitems_result_box = $('#noitems_result');

            if(noitems_result_box.find('.value').first().html() != noitems.value){
                noitems_result_box.find('.value').first().html(noitems.value);
            }

            if(!noitems.value){
                noitems_result_box.find('.value').parent().hide();
            }
            else{
                noitems_result_box.find('.value').parent().show();
            }
            
            if(noitems.agreement)
            {
                liveCommentBoxUpdate('#div_agreement_noitems',noitems.agreement.content);
            }
            else
            {
                $('#div_agreement_noitems').hide();
                $('#div_agreement_noitems').find('.value').first().html('');
            }
            
            $('.appendixContent[data-id="0"][data-type="no_comment"]').replaceWith(noitems.appendix);
            noitems_result_box.parent().effect("highlight", {}, live_preview_highlight_time);
        }

        // agreements
        /*var html = '';
        for(i = 0; i < data.agreements.length; i++) {
            var formsessionagreement = data.agreements[i];
            html += '<li class="list-group-item" style="height:45px;">- '+formsessionagreement.content+'</li>';
        }
        //agreements_result_div
        var agreements_result_div = $('#agreements_result_div');

        var highlight = false;
        if(agreements_result_div.find('ul').first().children().length != data.agreements.length) {
            highlight = true;
        }
        agreements_result_div.find('ul').first().html(html);
        if(highlight){
            agreements_result_div.effect("highlight", {}, live_preview_highlight_time);
        }*/
       
       //agreements_result_div
	   var agreements_result_div = $('#agreements_result_div');
	   
       if(data.agreements.length) {
	       var html = '';
	        for(i = 0; i < data.agreements.length; i++) {
	            var formsessionagreement = data.agreements[i];
	            html += '<tr><td>'+formsessionagreement.content+'</td></tr>';
	        }
	        
	        var highlight = false;
	        if(agreements_result_div.find('tbody').first().children().length != data.agreements.length) {
	            highlight = true;
	        }
	        agreements_result_div.find('tbody').first().html(html);
	        if(highlight){
	            agreements_result_div.effect("highlight", {}, live_preview_highlight_time);
	        }
	        agreements_result_div.show();
       } else {
       	agreements_result_div.find('tbody').first().html('');
       	agreements_result_div.hide();
       }
        
    }
    set_formsession_last_update_time();
}

function liveCommentBoxUpdate(divID,value)
{
    var box = $(divID);

    if(box.find('.value').first().html() != value){
        box.find('.value').first().html(value);

        if(value){
            box.show();
            box.effect("highlight", {}, live_preview_highlight_time);
        }
        else{
            box.hide();
        }
    }
}

/**
 * Set the last update time. From this point the next update will be requested
 */
function set_formsession_last_update_time(){
    live_preview_last_update = Math.floor(Date.now() / 1000);
}

/**
 * Save formsessionfield entry
 * @param formsessionfield_id
 * @param values
 */
function saveFormsessionfield(formsessionfield_id, values, useCommentBtn, showNotice)
{
    if (useCommentBtn === undefined) 
    {
        useCommentBtn = false;
    }

    if (showNotice ==  undefined)
    {
        showNotice = false;
    }
	var saveBtn = $('#formsessionfield_'+formsessionfield_id).next().find('.save');
	saveBtn.addClass('loading').html('<img style="padding-left:10px;" src="/gfx/reloader.gif">');
	
    values['formsessionfield_id'] = formsessionfield_id;
    values['_token'] = csrf_token;

    $.ajax({
        type: "POST",
        url: url_save_field,
        data: values
    }).then(function (data) {
        if(data.success == 0){
            alert(errormsg);
        }
        hideSmallLoader();
        saveBtn.removeClass('loading').html('<span class="glyphicon glyphicon-ok"></span>');
        
        if(showNotice) {
	        if(useCommentBtn) {
	        	toastr.success('Opmerking is meegenomen', { timeOut: 10000 });
	        } else {
	        	toastr.success('Opmerking is opgeslagen', { timeOut: 10000 });
	        }	
        }
        
    }).fail(function() {
        alert(errormsg);
        hideSmallLoader();
    });
}

