function createFormsessionAgreementByConnection(connected_to,connected_to_id,value,finishClosure)
{
	if(connected_to != null && connected_to_id != null) {
		var saveBtn = $('#connected_agreements_div_'+connected_to+'_'+connected_to_id).find('.buttons').find('.save');
	} else if(connected_to == 'noitems') {
		var saveBtn = $('textarea[name="no_items_agreements"]').next('.buttons').find('.save');
	} else {
		var saveBtn = $('.add_agreements_field').next('.buttons').find('.save');
	}

	saveBtn.addClass('loading').html('<img style="padding-left:10px;" src="/gfx/reloader.gif">');
	
	//console.log(connected_to, connected_to_id);
    var values = {
        '_token': csrf_token,
        'connected_to': connected_to,
        'connected_to_id': connected_to_id,
        'content': value
    };

    showSmallLoader();
    $.ajax({
        type: "POST",
        url: url_agreements_new,
        data: values
    }).then(function (data) {
        if(data.success == 0){
            alert(errormsg);
        }
        else if(finishClosure)
        {
            finishClosure(data);
        }
        hideSmallLoader();
        saveBtn.removeClass('loading').html('<span class="glyphicon glyphicon-ok"></span>');
        toastr.success('Afspraak is opgeslagen', { timeOut: 10000 });
    }).fail(function() {
        alert(errormsg);
        hideSmallLoader();
    });
}
function createFormsessionAgreement(value,finishClosure)
{
    createFormsessionAgreementByConnection(null,null,value,finishClosure);
}
function updateFormsessionAgreement(formsessionagreement_id,value,finishClosure)
{
	var saveBtn = $('textarea[formsessionagreement_id="'+formsessionagreement_id+'"]').next().find('.save');
	saveBtn.addClass('loading').html('<img style="padding-left:10px;" src="/gfx/reloader.gif">');
	    
	// console.log('start update');
    var values = {
        '_token': csrf_token,
        'content': value
    };

    showSmallLoader();
    $.ajax({
        type: "POST",
        url: url_agreements_edit.replace(':id',formsessionagreement_id),
        data: values
    }).then(function (data) {
        if(data.success == 0){
            alert(errormsg);
        }
        else if(finishClosure) {
            finishClosure(data);
        }
        updateAgreementsBox(data);
        hideSmallLoader();
        saveBtn.removeClass('loading').html('<span class="glyphicon glyphicon-ok"></span>');
        toastr.success('Afspraak is opgeslagen', { timeOut: 10000 });
    }).fail(function() {
        alert(errormsg);
        hideSmallLoader();
    });
}
function updateAgreementsBox(data) {
	if($('#agreements_table').find('tr#agreement_row_'+data.formsessionagreement_id).length > 0) {
		
		$('#agreements_table').find('tr#agreement_row_'+data.formsessionagreement_id).find('.show_box').html(nl2br(data.content));
		
	} else {
		
		var html = '';

    	html += '<tr id="agreement_row_'+data.formsessionagreement_id+'">';
    	
    	html += '<td>';
    	html += '<div class="show_box">';
    	html += nl2br(data.content);
    	html += '</div>';
    	html += '</td>';
    	
    	html += '<td style="width:134px; text-align: right;">';
    	html += '<button style="margin-right:4px;" class="btn btn-primary edit_agreement" data-id="'+data.formsessionagreement_id+'" type="button" title="Afspraak wijzigen">';
    	html += '<span class="glyphicon glyphicon-pencil"></span>';
    	html += '</button>';
    	html += '<button class="btn btn-danger delete_agreement" data-id="'+data.formsessionagreement_id+'" type="button" title="Afspraak verwijderen">';
    	html += '<span class="glyphicon glyphicon-trash"></span>';
    	html += '</button>';
    	html += '</td>';
    	
    	html += '</tr>';
    	
    	if($('#agreements_table').find('tbody').length == 0) {
    		$('#agreements_table').append('<tbody>'+html+'</tbody>').show();
    	} else {
    		$('#agreements_table > tbody').append(html);
    	}
    
		
	}
}
function deleteFormsessionAgreement(formsessionagreement_id,finishClosure)
{
	
	var saveBtn = $('textarea[formsessionagreement_id="'+formsessionagreement_id+'"]').next().find('.save');
	saveBtn.addClass('loading').html('<img style="padding-left:10px;" src="/gfx/reloader.gif">');
	
    var values = {
        '_token': csrf_token
    };

    showSmallLoader();
    $.ajax({
        type: "POST",
        url: url_agreements_delete.replace(':id',formsessionagreement_id),
        data: values
    }).then(function (data) {
        if(data.success == 0){
            alert(errormsg);
        }
        else if(finishClosure) {
            finishClosure(data);
        }
        $('#agreements_table').find('tr#agreement_row_'+formsessionagreement_id).remove();
        hideSmallLoader();
        saveBtn.removeClass('loading').html('<span class="glyphicon glyphicon-ok"></span>');
        toastr.success('Afspraak is verwijderd', { timeOut: 10000 });
    }).fail(function() {
        alert(errormsg);
        hideSmallLoader();
    });
}

// non-connected agreements
$('.agreements_update_field').change(actionChangeAgreementUpdateField);

function actionChangeAgreementUpdateField()
{
    actionUpdateFormsessionAgreement($(this).attr('formsessionagreement_id'),$(this).val());
}

function actionUpdateFormsessionAgreement(formsessionagreement_id,content)
{
    if(!content)
    {
        deleteFormsessionAgreement(formsessionagreement_id,function() {
            $('#agreement_row_' + data.formsessionagreement_id).remove();
        });
    }
    else
    {
        updateFormsessionAgreement(formsessionagreement_id,content,function(data){
            var agreement_row = $('#agreement_row_' + data.formsessionagreement_id);

            var edit_box = agreement_row.find('.edit_box').first();
            edit_box.hide();

            var value = edit_box.children().first().val();

            var show_box = agreement_row.find('.show_box').first();
            show_box.html(value);
            show_box.show();
        });
    }
}

$('.action_show_update_agreement_box').click(actionEditFormsessionAgreement);

function actionEditFormsessionAgreement()
{
    var agreement_row = $('#agreement_row_' + $(this).attr('formsessionagreement_id'));
    agreement_row.find('.show_box').first().hide();
    agreement_row.find('.edit_box').first().show();
}

$('.action_formsession_delete_agreement').click(actionDeleteFormsessionAgreement);

function actionDeleteFormsessionAgreement()
{
    if(confirm('Weet u zeker dat u deze afspraak wilt verwijderen?'))
    {
        deleteFormsessionAgreement($(this).attr('formsessionagreement_id'),function(data){
            $('#agreement_row_' + data.formsessionagreement_id).remove();
        });
    }
}

$('#add_agreement_button').click(showAddAgreementBox);
$('#add_agreement_cancel_button').click(hideAddAgreementBox);

/*$('#add_agreement_save_button').click(function(){
    var val = $('#add_agreement_input_field').val();

    if(val)
    {
        createFormsessionAgreement(val,function(data){
            addAgreementToTable(data.formsessionagreement_id,data.content);
            hideAddAgreementBox();
        });
    }
    else
    {
        hideAddAgreementBox();
    }
});*/

function showAddAgreementBox()
{
    $('#add_agreement_box').show();
    $('#add_agreement_button').hide();
}
function hideAddAgreementBox()
{
    $('#add_agreement_box').hide();
    $('#add_agreement_button').show();
    $('#add_agreement_input_field').val(''); // clear value
}
function addAgreementToTable(formsessionagreement_id,content)
{
    var html = '';

    html += '<tr id="agreement_row_'+formsessionagreement_id+'">';
    html += '<td>';
    html += '<div class="show_box">'+nl2br(content)+'</div>';

    html += '<div class="edit_box" style="display: none; position:relative;">';
    html += '<textarea name="" class="form-control agreements_update_field" cols="20" rows="4" formsessionagreement_id="' + formsessionagreement_id + '">' + content + '</textarea>';
    html += '</div>';
    
    html += '<td style="width:134px; text-align: right;">';
	html += '<button style="margin-right:4px;" class="btn btn-primary edit_agreement" data-id="'+formsessionagreement_id+'" type="button" title="Afspraak wijzigen">';
	html += '<span class="glyphicon glyphicon-pencil"></span>';
	html += '</button>';
	html += '<button class="btn btn-danger delete_agreement" data-id="'+formsessionagreement_id+'" type="button" title="Afspraak verwijderen">';
	html += '<span class="glyphicon glyphicon-trash"></span>';
	html += '</button>';
	html += '</td>';

    /*html += '</td>';
    html += '<td>';
    html += '<button type="button" class="btn btn-primary action_show_update_agreement_box" formsessionagreement_id="'+formsessionagreement_id+'">Wijzigen</button>';
    html += ' <button type="button" class="btn btn-danger action_formsession_delete_agreement" formsessionagreement_id="'+formsessionagreement_id+'">Verwijderen</button>';
    html += '</td>';
    html += '</tr>';*/

    $('#agreements_table').append(html);

    // add event listeners
    $('.action_formsession_delete_agreement').click(actionDeleteFormsessionAgreement);
    $('.action_show_update_agreement_box').click(actionEditFormsessionAgreement);
    $('.agreements_update_field').change(actionChangeAgreementUpdateField);

    $('#agreements_table').show();
}

var deletePressed = false;
$('body').delegate('textarea.agreements_field + .buttons > .delete', 'mousedown', function(evt) {
	evt.preventDefault();
	
	deletePressed = true;
	var saveBtn = $(this).parent().find('.save');
	
	$(this).parent().parent().parent().find('textarea').val('');
	$(this).parent().parent().parent().find('textarea').css('height', '68px');
	$(this).parent().parent().parent().hide();
	
	var formsessionagreement_id = $(this).parent().parent().parent().find('textarea').attr('formsessionagreement_id');
	if(formsessionagreement_id > 0){
		deleteFormsessionAgreement(formsessionagreement_id,function() {});
	}
	
	$(this).parent().parent().parent().find('textarea').attr('formsessionagreement_id', '');
	$(this).parents('.panel').find('button.open_no_items_connected_agreements_div').attr('disabled', false);
	
	deletePressed = false;
	/*var $request = $.ajax({
        type: "POST",
        url: '{{route('forms.formsessions.save_pre_comments',[$formsession->id])}}',
        data: {
            _token: csrf_token,
            type: $(this).parent().parent().find('textarea').attr('comment_type'),
            type_id: $(this).parent().parent().find('textarea').attr('type_id'),
            comments: ''
        }
    });
    $request.then(function (data) {
        console.log(data);
        toastr.success('Opmerking is verwijderd', { timeOut: 10000 });
        saveBtn.removeClass('loading').html('<span class="glyphicon glyphicon-floppy-disk"></span>');
    });*/
	
	//$(this).find('.comments_field').val('').parent().hide();
});

	

// formsessionfield and formsessionitem agreement fields
/**
 * update handler for agreements field-div
 */
$('.connected_agreements_div textarea').blur(function(){
    var textarea = $(this);
    if(deletePressed == false && (textarea.val() !== $agreements_val)) {
    	var content = $(this).val();
	    var formsessionagreement_id = $(this).attr('formsessionagreement_id');
	    if (formsessionagreement_id)
	    {
            updateFormsessionAgreement(formsessionagreement_id,content,function (){});
	    }
	    else if(content)
	    {
	        createFormsessionAgreementByConnection($(this).attr('field_type'),$(this).attr('field_type_id'),content,function(data){
	            textarea.attr('formsessionagreement_id',data.formsessionagreement_id);
	            updateAgreementsBox(data);
	        });
	    }

        // Remove fromsessionagreement id
        if (!content)
        {
            $(this).attr('formsessionagreement_id', '');
        }
	}
	deletePressed = false;
});

$('textarea.agreements_field').on('focusin', function()
{
    $agreements_val = $(this).val();
});

$('.connected_agreements_div button').click(function(){
    var div = $(this).parent().parent().parent();
    var textarea = div.find('textarea').first();

    var formsessionagreement_id = textarea.attr('formsessionagreement_id');

    if(formsessionagreement_id)
    {
        textarea.val('');
        textarea.attr('formsessionagreement_id','');
        deleteFormsessionAgreement(formsessionagreement_id,function() {});
    }

    div.hide();
    $('#connected_agreements_btn_' + textarea.attr('field_type') + '_' + textarea.attr('field_type_id')).show();
});

$('.open_connected_agreements_div').click(function() {
    $('#connected_agreements_div_' + $(this).attr('field_type') + '_' + $(this).attr('field_type_id')).show();

    return false;
});