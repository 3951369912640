// editable
var url_notes_show = '/api/notes/:type/:type_id/show';
var url_notes_get = '/api/notes/:type/:type_id/get/:id';
var url_notes_update = '/api/notes/:type/:type_id/update';
var url_notes_delete = '/api/notes/:type/:type_id/delete';
var url_helpitems_get = '/api/helpitems/';
var label_btn_note_show = 'Toon notitieblad';
var label_btn_note_hide = 'Verberg notitieblad';
var label_btn_note_loading = 'Notitie wordt geladen...';
var label_note_title_formsession = 'Notities over dit formulier';
var label_note_title_pop = 'Notities over deze POP';
var label_btn_help_show = 'Help';
var label_btn_help_hide = 'Verberg help';

// note editable
var noteIsUpdating = false;
var noteNeedsToUpdate = false;
var noteIsGettingCurrenState = false;
var showNewNote = false;

function notesEnable(type,type_id){
	$('#footerBox').show();
    $('.footerBoxNoteButton').show();
    $('#popupBoxNote').attr('data-note-type',type);
    $('#popupBoxNote').attr('data-note-type-id',type_id);

    if(type == 'formsession'){
        $('#popupBoxNoteTitle').html(label_note_title_formsession);
    }
    else if(type == 'pop'){
        $('#popupBoxNoteTitle').html(label_note_title_pop);
    }

    getNoteState();

    $('.footerBoxNoteButton').click(function(){
        if(!noteIsGettingCurrenState) {
            if ($('#popupBoxNote').is(':visible')) {
                hideNoteBox();
            }
            else {
                showNoteBox();
            }
        }
    });

	$('.footerBoxNoteSaveButton').click(function(event){ 
	//   $( "#popupBoxNote" ).delegate( "#popupBoxNoteContent", "change", function(evt) {
   		noteNeedsToUpdate = true;
        updateNote(true);
   });
   
	$( "#popupBoxNote" ).delegate( ".list-group-item", "click", function(evt) {
   		evt.preventDefault();
   		getNote($(this));
   });
   
   $( "#popupBoxNote" ).delegate( ".delete_note", "click", function(evt) {
   		evt.preventDefault();
   		el = $(this);

        var confirmbox = confirm('Weet u zeker dat u deze notitie wilt verwijderen?');
        if(confirmbox){
        	$('#popupBoxNote .popupBoxNoteContent').hide();
        	deleteNote(el);
        }
   });
   
   $('.edit_title').click(function(){

        var oldValue = $(this).parent().find('span > span').text();
        if(oldValue) {
        	var newValue = prompt("Wilt u een label/titel wijzigen?", oldValue);
        } else {
        	var newValue = prompt("Wilt u een label/titel toevoegen?", oldValue);
        }

        if(newValue)
        {
            $(this).parent().find('span > span').text(newValue);
            $('#popupBoxNote .popupBoxNoteContent .popupBoxNoteSubtitle').show();
            $('.list-group-item[data-id="'+$(this).data('id')+'"] em').text('- '+newValue);
            $('#popupBoxNote .popupBoxNoteContent .popupBoxNoteInputSubtitle > input').val(newValue);
        } else {
        	$(this).parent().find('span > span').text('');
        	$('#popupBoxNote .popupBoxNoteContent .popupBoxNoteSubtitle').hide();
        	$('.list-group-item[data-id="'+$(this).data('id')+'"] em').text('');
        	$('#popupBoxNote .popupBoxNoteContent .popupBoxNoteInputSubtitle > input').val('');
        }
        
        noteNeedsToUpdate = true;
        updateNote(false);
    });
}

$('.footerBoxHelpButton_small, .footerBoxHelpButton, #helpbutton').click(function(event)
{
    event.preventDefault();
    
    if ($('#popupBoxHelp').is(':visible')) 
    {
        $('.footerBoxHelpButton_small').show();
        hideHelpBox();
    }
    else {
        showHelpBox();
        $('.footerBoxHelpButton_small').hide();
    }
});

$('.footerBoxContactButton, #contactbutton').click(function(event)
{
    event.preventDefault();

    if ($('#popupBoxContact').is(':visible')) 
    {
        //$(this).show();
        hideContactBox();
    }
    else 
    {
        //$(this).hide();
        showContactBox();
    }
});

$('.footerBoxNoteNewButton').click(function(event)
{
    event.preventDefault();
    
    showNewNote = true;
    showEmptyNote();
});


/*
Check if there is an existing note. if there is, the notebox will be showed with the content.
 */
function getNoteState(){
    var url = url_notes_show.replace(':type_id',$('#popupBoxNote').data('note-type-id'));
    url = url.replace(':type',$('#popupBoxNote').data('note-type'));

    noteIsGettingCurrenState = true;
    $('.footerBoxNoteButton').html(label_btn_note_loading);

    $.getJSON( url )
    .done(function( json ) {
        noteIsGettingCurrenState = false;

        if(json.notes){
        	
        	$.each(json.notes, function(index, note){
        		$('#popupBoxNote .list-group').append('<div style="position:relative;"><a href="#" data-id="'+note.id+'" class="list-group-item'+(index%2?" helpitem-colored ":"")+'" style="text-align: left; color: #7557b3;">'+note.created_at+' '+(note.title?'<em>- '+note.title+'</em>':'<em></em>')+'</a><button class="btn btn-danger delete_note" type="button" data-id="'+note.id+'" style="float: right; right: 4px; top: 2px; position: absolute;"><span class="glyphicon glyphicon-trash" style="font-size: 12px;"></span></button><div>');
        	});
        	$('#popupBoxNote .hasNotes').show();
        	$('.footerBoxNote').append('<div class="badge">' + json.notes.length + '</div>');
        } else {
        	$('#popupBoxNote .title').hide();
        	$('#popupBoxNote .popupBoxNoteContent').show();
        	
    		
        }
        
        $('.footerBoxNoteButton').html(label_btn_note_show);

    })
    .fail(function( jqxhr, textStatus, error ) {

    });
}

/*
show note box and update the note-button label
 */
function showNoteBox(){
    //hideHelpBox();
    //hideContactBox();
    $('#footerBox').show();
    $('#popupBoxNote').show();
    $('.footerBoxNoteButton').html(label_btn_note_hide);
    
    var count = $('#popupBoxNote .list-group-item').length;
    if(count > 0) {
    	$('.footerBoxNoteNewButton').show();
    	$('.footerBoxNoteSaveButton').hide();
    } else {
    	$('.footerBoxNoteNewButton').hide();
    	$('.footerBoxNoteSaveButton').show();
    }
    
    
}
/*
 hide note box and update the note-button label
 */
function hideNoteBox(){
    $('#popupBoxNote').hide();
    //$('#footerBox').hide();
    //$('#popupBoxNote .popupBoxNoteContent').hide();
    $('.footerBoxNoteButton').html(label_btn_note_show);
    
    $('.footerBoxNoteSaveButton').hide();
    $('.footerBoxNoteNewButton').hide();
}
/*
 show help box and update the note-button label
 */
function showHelpBox()
{
    hideNoteBox();
    hideContactBox();

    if (library_page)
    {
        $('#disclaimer-library-button').hide();
    }
    else
    {
        $('#footerBox').show();
    }
    
    $('#popupBoxHelp').show();
    $('#popupBoxNote').hide();
    $('#footerBoxHelpButtonLbl').html(label_btn_help_hide);
    $('.footerBoxContactButton').hide();
    $('.footerBoxNoteSaveButton').hide();
    $('.footerBoxNoteButton').hide();
    $('.footer').css('background', 'linear-gradient(#a08ecc, #6bbada, #5cb6da)');

    loadHelpitems();
}
/*
 hide help box and update the note-button label
 */
function hideHelpBox()
{
    if (library_page)
    {
        $('#disclaimer-library-button').show();
    }
    else
    {
        $('#footerBox').hide();
    }

    $('#popupBoxHelp').hide();
    $('#footerBoxHelpButtonLbl').html(label_btn_help_show);
    //$('.footerBoxContactButton').show();
    
    if($('#popupBoxNote').data('note-type') != ''){
    	$('#footerBox').show();
    	$('.footerBoxNoteButton').show();
    }
    
    $('.footer').css('background', '#f9f9f9');
}
/*
show note box and update the note-button label
 */
function showContactBox()
{
    hideNoteBox();
    hideHelpBox();

    if (library_page)
    {
        $('#disclaimer-library-button').hide();
    }
    else
    {
        $('#footerBox').show();    
    }

    $('#popupBoxContact').show();
    $('#popupBoxNote').hide();
    $('.footerBoxContactButton').hide();
    $('.footerBoxNoteButton').hide();
    $('.footer').css('background', 'linear-gradient(0deg, #d3c4e8, #6bbada, #7cd9fd)');
        // background: linear-gradient(55deg, #F5FD93, #FFB286, #FFA9D3);
    /*background: linear-gradient(55deg, #bdeefd, #8b80e9);*/
/*    background: #355C7D; 
    background: -webkit-linear-gradient(to top, #C06C84, #6C5B7B, #355C7D);
    background: linear-gradient(to top, #C06C84, #6C5B7B, #355C7D);*/
}
/*
 hide note box and update the note-button label
 */
function hideContactBox()
{
    if (library_page)
    {
        $('#disclaimer-library-button').show();
    }
    else
    {
        $('#footerBox').hide();
    }

    $('#popupBoxContact').hide();
    // $('.footerBoxNoteButton').show();
    //$('.footerBoxContactButton').show();
    $('.footer').css('background', '#f9f9f9');
    $('.footer #popupBoxNote #popBoxHelpList .list-group-item').removeClass('active');
    
    if($('#popupBoxNote').data('note-type') != ''){
    	$('#footerBox').show();
    	$('.footerBoxNoteButton').show();
    }
}

/*
Update the content of the note.
 */
function updateNote(closeNote){
    if(!noteIsUpdating && noteNeedsToUpdate){
        noteNeedsToUpdate = false;
        noteIsUpdating = true;
        
        var url = url_notes_update.replace(':type_id',$('#popupBoxNote').data('note-type-id'));
        url = url.replace(':type',$('#popupBoxNote').data('note-type'));

        $.post( url, {
        	id: $('.popupBoxNoteContent').find('#popupBoxNoteContent').data('id'),
            value: $('.popupBoxNoteContent').find('#popupBoxNoteContent').val(),
            //title: $('.popupBoxNoteContent').find('.popupBoxNoteSubtitle > span').text(),
            title: $('.popupBoxNoteContent').find('.popupBoxNoteInputSubtitle > input').val(),
            _token: csrf_token
        })
        .done(function(json) {
        	$('.popupBoxNoteContent').find('#popupBoxNoteContent').data('id', json.note.id);
        	
        	if(json.note.new) {
        		var index = $('#popupBoxNote .list-group-item').length;
        		$('#popupBoxNote .list-group').append('<div style="position:relative;"><a href="#" data-id="'+json.note.id+'" class="list-group-item'+(index%2?" helpitem-colored ":"")+'" style="text-align: left; color: #7557b3;">'+json.note.created_at+' '+(json.note.title?'<em>- '+json.note.title+'</em>':'<em></em>')+'</a><button class="btn btn-danger delete_note" type="button" data-id="'+json.note.id+'" style="float: right; right: 4px; top: 2px; position: absolute;"><span class="glyphicon glyphicon-trash" style="font-size: 12px;"></span></button><div>');
        	
        		$('#popupBoxNote .popupBoxNoteContent .title').show();
        		$('#popupBoxNote .popupBoxNoteContent .panel-title a').data('id', json.note.id);
    			$('#popupBoxNote .popupBoxNoteContent .panel-title .popupBoxNoteTitle').text(json.note.created_at);
    			$('#popupBoxNote .popupBoxNoteContent .popupBoxNoteSubtitle').hide();
    	
        		$('#popupBoxNote .hasNotes').show();
        		$('.footerBoxNote').append('<div class="badge">' + json.note.amount + '</div>');
        	}
        	
            noteIsUpdating = false;
            if(noteNeedsToUpdate){
                updateNote(true);
            }
            
            if(closeNote){
            	$('.footer #popupBoxNote #popBoxHelpList .list-group-item').removeClass('active');
	            $('#popupBoxNote .popupBoxNoteContent').hide();
	            $('.footerBoxNoteSaveButton').hide();
	    		$('.footerBoxNoteNewButton').show();
    		}
    
            /*if(showNewNote) {
            	showEmptyNote();
            }*/
        });
    }
}

function deleteNote(el) {
	var url = url_notes_delete.replace(':type_id',$('#popupBoxNote').data('note-type-id'));
    url = url.replace(':type',$('#popupBoxNote').data('note-type'));
    
    $('.footer #popupBoxNote #popBoxHelpList .list-group-item').removeClass('active');
        
	$.post( url, {
    	id: el.data('id'),
        _token: csrf_token
    })
    .done(function(json) {
    	el.parent().remove();
    	
    	if(json.success && json.amount == 0) {
    		$('#popupBoxNote .hasNotes').hide();
    		$('.footerBoxNote .badge').hide();
    		
    		$('.footerBoxNoteNewButton').hide();
    		$('.footerBoxNoteSaveButton').hide();
    		showEmptyNote();
    	} else {
    		$.each($('#popupBoxNote .list-group-item'), function(index, row){
    			$(row).removeClass('helpitem-colored');
	    		if(index%2) {
	    			$(row).addClass('helpitem-colored');
	    		}
	    	});
	    	$('.footerBoxNote .badge').text(json.amount);
    	}
    	
    	$('.footerBoxNoteNewButton').show();
    	$('.footerBoxNoteSaveButton').hide();
    });
}

function getNote(el) {
	var url = url_notes_get.replace(':type_id',$('#popupBoxNote').data('note-type-id'));
    url = url.replace(':type',$('#popupBoxNote').data('note-type'));
    url = url.replace(':id',$(el).data('id'));
        
	$.getJSON( url )
    .done(function(json) {
    	
    	$('.footer #popupBoxNote #popBoxHelpList .list-group-item').removeClass('active');
    	$('.footer #popupBoxNote #popBoxHelpList .list-group-item[data-id="'+json.note.id+'"]').addClass('active');
    	
    	$('#popupBoxNote .popupBoxNoteInputSubtitle').hide();
    	$('#popupBoxNote .popupBoxNoteContent .title').show();
    	$('#popupBoxNote .popupBoxNoteContent .panel-title .popupBoxNoteTitle').text(json.note.created_at);
    	$('#popupBoxNote .popupBoxNoteContent .panel-title .popupBoxNoteSubtitle span').text(json.note.title);
    	$('#popupBoxNote .popupBoxNoteContent .popupBoxNoteInputSubtitle > input').val(json.note.title);
    	
    	$('#popupBoxNote .popupBoxNoteContent .panel-title a').data('id', json.note.id);
    	$('#popupBoxNote .popupBoxNoteContent #popupBoxNoteTitle').val(json.note.title).data('id', json.note.id);
    	$('#popupBoxNote .popupBoxNoteContent #popupBoxNoteContent').val(json.note.value).data('id', json.note.id);
    	
    	if(json.note.title == '') {
    		$('#popupBoxNote .popupBoxNoteContent .popupBoxNoteSubtitle').hide();
    	} else {
    		$('#popupBoxNote .popupBoxNoteContent .popupBoxNoteSubtitle').show();
    	}
    	
    	$('#popupBoxNote .popupBoxNoteContent').show();
    	
    	$('.footerBoxNoteNewButton').hide();
    	$('.footerBoxNoteSaveButton').show();
    });
}

function showEmptyNote() {
	$('#popupBoxNote .popupBoxNoteSubtitle > span').text('');
	$('#popupBoxNote .popupBoxNoteInputSubtitle > input').val('');
	
	$('#popupBoxNote .popupBoxNoteInputSubtitle').show();
	$('#popupBoxNote .popupBoxNoteContent .title').hide();
	$('#popupBoxNote .popupBoxNoteContent .panel-title a').removeData('id');
    $('#popupBoxNote .popupBoxNoteContent #popupBoxNoteTitle').val('').removeData('id');
    $('#popupBoxNote .popupBoxNoteContent #popupBoxNoteContent').val('').removeData('id');
    $('#popupBoxNote .popupBoxNoteContent').show();
    
    $('.footerBoxNoteNewButton').hide();
    $('.footerBoxNoteSaveButton').show();
    
    showNewNote = false;
}

function loadHelpitems() 
{
    $.getJSON( url_helpitems_get + '?tags=' + helpitemTags).done(function(data) 
    {
        let html = '';
        
        if (!data.items.length) 
        {
            //
        }
        else
        {
            for (i = 1; i <= data.items.length; i++) 
            {
                let item = data.items[i - 1];

                // Change background for every second element
                if (i % 2 == 0)
                {
                    html += '<a href="' + item.url + '" class="list-group-item helpitem-colored" target="_blank" style="text-align: left; color: #7557b3;">' + item.title + '</a>';    
                }
                else
                {
                    html += '<a href="' + item.url + '" class="list-group-item" target="_blank" style="text-align: left; color: #7557b3;">' + item.title + '</a>';
                }
            }
        }

        if (data.remaining_items && data.remaining_items.length)
        {
            html += '<hr style="margin-top: 15px; margin-bottom: 15px; border-top: 2px solid #fff;">';

            for (i = 1; i <= data.remaining_items.length; i++) 
            {
                let item = data.remaining_items[i - 1];

                // Change background for every second element
                if (i % 2 == 0)
                {
                    html += '<a href="' + item.url + '" class="list-group-item helpitem-colored" target="_blank" style="text-align: left; color: #7557b3;">' + item.title + '</a>';    
                }
                else
                {
                    html += '<a href="' + item.url + '" class="list-group-item" target="_blank" style="text-align: left; color: #7557b3;">' + item.title + '</a>';
                }
            }
        }
        $('#popupBoxHelp #popBoxHelpList').html(html);
    });
}

$('#contact_close').on('click', hideContactBox);

// On form submit
$('#contact_form').submit(function(event)
{
    event.preventDefault();

    $title_element = $('#contact-form-title');
    $message_element = $('#contact-form-text');

    // Check is the title is set, if not add has-error class and show error message
    if (!$title_element.val().length)
    {
        $form_group = $title_element.closest('.form-group');

        if (!$form_group.has('p').length)
        {
            $form_group.addClass('has-error');
            $form_group.append('<p style="font-weight: bold; font-size: 13px;" class="help-block">U dient een titel in te vullen</p>');
        }
    }
    else
    {
        $form_group = $title_element.closest('.form-group');

        if ($form_group.hasClass('has-error'))
        {
            $form_group.removeClass('has-error');
            $form_group.find('p').remove();
        }
    }

    // Check is the title is set, if not add has-error class and show error message
    if (!$message_element.val().length)
    {
        $form_group = $message_element.closest('.form-group');

        if (!$form_group.has('p').length)
        {
            $form_group.addClass('has-error');
            $form_group.append('<p style="font-weight: bold; font-size: 13px;" class="help-block">U dient een bericht in te vullen</p>');
        }
    }
    else
    {
        $form_group = $message_element.closest('.form-group');

        if ($form_group.hasClass('has-error'))
        {
            $form_group.removeClass('has-error');
            $form_group.find('p').remove();
        }
    }

    // Do post request if both elements have a value
    if ($title_element.val() && $message_element.val())
    {
        // Do post request
        $.post('/contact/new',
        {
            _token: csrf_token,
            user_id: userID,
            title: $title_element.val(),
            message: $message_element.val()
        },
        function(data)
        {
            if (data.status == 'success')
            {
                $('#contact-form-title').val('');
                $('#contact-form-text').val('');

                hideContactBox();
                toastr.success('U ontvangt een bevestigingsmail', 'Uw vraag is verzonden', { timeOut: 10000 });
            }
            else if (data.status == 'error')
            {
                toastr.error('Probeer het later opnieuw of neem telefonisch contact op', 'Er ging iets mis tijdens het verwerken van uw vraag', { timeOut: 10000 });
            }
        }).error(function(xhr, statusText, error)
        {
            if (statusText == 'error')
            {
                toastr.error('Probeer het later opnieuw of neem telefonisch contact op', 'Er ging iets mis tijdens het versturen van uw vraag', { timeOut: 10000 });
            }
        })
    }
});


// function contactRequest(route)
// {
//     $.post('/contact/new',
//     {
//         _token: csrf_token,
//         user_id: userID
//     },function(data, status)
//     {
//         alert("Data: " + data + "\nStatus: " + status);
//     });
// }