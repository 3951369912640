// Linear gradient colors for headers
function bottombarColors(index)
{
	let colors = 
	{
		1: "to right, #f99c92, #f5a841",
		2: "to right, #4fa1b0, #b1d25d",
		3: "to right, #f66880, #f39c66",
		4: "to right, #ea5f52, #ebda68",
		5: "to right, #78a7b5, #d5d859",
		6: "to right, #eb789a, #fce93f",
		7: "to right, #95cd6f, #e4d62b",
		8: "to right, #d264a6, #4f8ac5"
	};

	if (index) return colors[index];

	return colors;
}

// RGB(A) colors for headers
function categoryColors(index, alpha)
{
	alpha = (typeof alpha !== 'undefined') ? alpha : 1;
	
	let colors =
	{
		1: 'rgba(247, 162, 108, ' + alpha + ')',
		2: 'rgba(106, 175, 152, ' + alpha + ')',
		3: 'rgba(245, 113, 123, ' + alpha + ')',
		4: 'rgba(234, 95, 82, ' + alpha + ')',
		5: 'rgba(120, 167, 181, ' + alpha + ')',
		6: 'rgba(237, 134, 142, ' + alpha + ')',
		7: 'rgba(162, 204, 76, ' + alpha + ')',
		8: 'rgba(181, 108, 172, ' + alpha + ')'
	};

	if (index) return colors[index];

	return colors;
}

// RGB(A) border colors
function borderColors(index, alpha)
{
	alpha = (typeof alpha !== 'undefined') ? alpha : 1;

	let colors =
	{
		1: 'rgba(127, 64, 23, ' + alpha + ')',
		2: 'rgba(97, 166, 197, ' + alpha + ')',
		3: 'rgba(144, 36, 44, ' + alpha + ')',
		4: 'rgba(127, 64, 23, ' + alpha + ')',
		5: 'rgba(97, 166, 197, ' + alpha + ')',
		6: 'rgba(127, 64, 23, ' + alpha + ')',
		7: 'rgba(89, 103, 19, ' + alpha + ')',
		8: 'rgba(127, 64, 23, ' + alpha + ')'
	};

	if (index) return colors[index];

	return colors;
}

/**
 * Set the backgroundcolor to a linear gradient for the given elements
 * @param elements 		Array of elements
 * @param category_id 	id of the color to use
 * @param color 		Color for the elements without class
 */
function setBackgroundColorGradient(elements, category_id)
{
	let colors = bottombarColors();

	if (category_id)
	{
		$.each(elements, function(key, value)
		{
			$(this).attr('style', 'background: linear-gradient('+colors[category_id]+')');
		});

		return;
	}

	let ids = [];
	$.each(elements, function(key, value)
	{
		// Loop through the elements
		$.each(elements[key], function()
		{
			// If the element has a numeric last class
			if (!($(this).attr('class') === undefined) && $.isNumeric($(this).attr('class').split(' ').pop()))
			{
				// Get the id and set the background color
				let id = $(this).attr('class').split(' ').pop();
				ids.push(id);
				$(this).attr('style', 'background: linear-gradient('+ colors[id] +')');
			}

			// If not numeric or no class
			else
			{
				// If ids is set, change colors
				if (ids.length)
				{
					$(this).css('background-color', colors[ids[0]]);
				}

				// Fallback color
				else
				{
					$(this).css('background-color', '#a0e2fc');
				}
			}
		});

		return;
	});
}

// Set the colors to the category for various elements on the page
function setCategoryColors(category_id)
{
	// Change the backgroundcolor for the given elements
	setBackgroundColor(
	[
		$('.forumtopic-header'),
		$('.categories-header'),
		$('.followers-header'),
		$('.bottomtabs ul li')
	], categoryColors(category_id));

	// Change topmenu button colors
	$('#topmenu.theme1 .forumnav.bottom .bottomtabs ul li').each(function(key, value)
	{
		this.style.setProperty('border', '2px solid ' + borderColors(category_id) + '', 'important');
		this.style.setProperty('padding-top', '8px');
	});

	// Change forumnav border color
	$('.forumnav.bottom')[0].style.setProperty('border', '2px solid ' + borderColors(category_id) + '', 'important');

	// Change topmenu button colors
	$('#topmenu.theme1 .top .toptabs ul li').each(function(key, value)
	{
		this.style.setProperty('border', '2px solid ' + borderColors(category_id) + ' ', 'important');
		this.style.setProperty('background', categoryColors(category_id), 'important');
		this.style.setProperty('box-shadow', 'none');

		$(this).find('a')[0].style.setProperty('color', '#fff');
	});

	// Change the breadcrumbs bar background color
	$('.breadcrumb').css('background-color', categoryColors(category_id, 0.2));

	// Change the color of the user images
	changeImageColors($('.user-image img'));

	// Change the current category title color
	changeSelectedCategoryColor(category_id);

	// Change the category text divider colors
	$('.category-text-divider').css('border', '1px solid ' + categoryColors(category_id));

	// Change the colors for the badges in the categories
	changeBadgeColors();
}


// Set the background color for the given elements
function setBackgroundColor(elements, color)
{
	$.each(elements, function(key, value)
	{
		$(this).css('background-color', color);
	});
}

// Change the badge colors for the categories
function changeBadgeColors(selector)
{
	let badges = (selector !== undefined) ? selector : $('.categories-badge.badge');

	badges.each(function()
	{
		let id = $(this).attr('class').substr($(this).attr('class').length - 1);
		setBackgroundColor($(this), categoryColors(id));
	});
}

// Change the colors for the selected category in the categories list
function changeSelectedCategoryColor(category_id)
{
	$('.category-title').each(function()
	{
		let id = $(this).attr('id').substr($(this).attr('id').length - 1);
		if (category_id === id) 
		{
			$(this).parent().css('color', categoryColors(category_id));
			setBackgroundColor(
			[
				$(this),
				$('.text-arrow-'+ category_id),
				$('#category-text-' + category_id)
			], categoryColors(category_id, 0.2));
		}
	});
}

// Change the background colors for the given images
function changeImageColors(images)
{
	images.each(function()
	{
		let id = $(this).attr('class').substr($(this).attr('class').length - 1);

		if ($(this).hasClass('anonymous') || $(this).hasClass('default-avatar'))
		{
			$(this).attr('style', 'background:' + categoryColors(id) + '');
		}
	});	
}

// Show the container..
function showContainer()
{
	$('.container').css('opacity', 1);
}

// Creates a tooltip and shows overlay on click
function showOverlay(selector, element)
{
	selector.tooltip({
		show: null,
		open: function( event, ui ) 
		{
		    ui.tooltip.animate({ top: ui.tooltip.position().top + 10 }, "fast" );
		},
			close: function( event, ui ) 
			{
	    	ui.tooltip.hover(
		        function () 
		        {
		            $(this).stop(true).fadeTo(400, 1);
	    		    $(this).css('cursor', 'pointer');
	    		    $('.ui-tooltip-content').css('text-decoration', 'underline');
		        },
		        function () 
		        {
		            $(this).fadeOut("400", function(){ $(this).remove(); })
		        }
		    );

		    ui.tooltip.click(function()
		    {
		    	on(element);
		    });
	  	},
	});

	// Show the disclaimer
	function on(element) 
	{
		element.show();
	}
}

// Hide the disclaimer
function off(element) 
{
	element.hide();
}