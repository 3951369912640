$(window).ready(function(){
    $('.toggleVisibilityElementsByClass').click(function(){
        var elementsclass = $(this).attr('elementsclass');
        if($('.'+elementsclass+':visible').size() > 0){
            $('.' + elementsclass).hide();
        }
        else{
            $('.' + elementsclass).show();
        }
    });

    $('.popovers').popover({
        trigger: 'hover',
        placement: 'bottom'
    });

    $(window).bind('beforeunload',function(){
        if(typeof checkForAjaxRequests !== 'undefined' && checkForAjaxRequests) {
            jQuery.ajax({url: "/auth/check/", async: false})
        }
    });

    /*
    if(typeof checkLogin !== 'undefined' && checkLogin) {
        setInterval(checkAuth, 10000);
    }
    */
});

/**
 * Check if user is logged in. otherwise reload page.
 */
function checkAuth()
{
    $.getJSON('/auth/check/',function(data)
    {
        if(!data.success)
        {
            location.reload();
        }
    })
}

// SELECT 2 userDepSelect
function initUserDepSelect(selectors,url){
    $(selectors).select2({
        ajax: {
            url: url,
            dataType: 'json',
            delay: 0,
            data: function (params) {
                return {
                    query: params.term, // search term
                    page: params.page
                };
            },
            processResults: function (data, page) {
                // parse the results into the format expected by Select2.
                // since we are using custom formatting functions we do not need to
                // alter the remote JSON data
                return {
                    results: data.results
                };
            },
            cache: true
        },
        escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
        minimumInputLength: 0,
        templateResult: formatRepoUserDepSelect, // omitted for brevity, see the source of this page
        templateSelection: formatRepoSelectionUserDepSelect // omitted for brevity, see the source of this page
    });
}
function formatRepoUserDepSelect (repo) {
    if(repo.loading) {
        var markup = '<div class="clearfix">Laden..</div>';
    }
    else{
        var markup = '<div class="clearfix">';

        markup += '<div class="col-sm-8">';
        if (repo.type == 'user') {
            markup += '<span class="glyphicon glyphicon-user"></span>';
        }
        else if(repo.type == 'tag'){
            markup += '<span class="glyphicon glyphicon-tag"></span>';
        }
        else if(repo.type == 'testcompetence'){
            markup += '<span class="glyphicon glyphicon-tags"></span>';
        }
        else {
            markup += '<span class="glyphicon glyphicon-briefcase"></span>';
        }
        markup += ' ' + repo.name + '';
        markup += '</div>';

        if (repo.type == 'department') {
            var countlabel = 'gebruiker';
            if (repo.users > 1) {
                countlabel += 's';
            }
            markup += '<div class="col-sm-4">' + repo.users + ' ' + countlabel + '</div>';
        }
        else if(repo.type == 'testcompetence'){
            var countlabel = 'tag';
            if (repo.tags > 1){
                countlabel += 's';
            }
            markup += '<div class="col-sm-4">' + repo.tags + ' ' + countlabel + '</div>';
        }

        markup += '</div>';
    }

    return markup;
}

function formatRepoSelectionUserDepSelect (repo) {
    // by init the objects are different compared to the ajax-json objects
    if(repo.text){
        return repo.text;
    }
    else {
        return repo.name;
    }
}

function showLoader(txt){
    $('#loader_title').html(txt);
    $('#loader').show();
}
function hideLoader(){
    $('#loader').hide();
}
function showSmallLoader(){
    $('#loader_small').show();
}
function hideSmallLoader(){
    $('#loader_small').hide();
}
$.fn.hasAttr = function(name) {
    return this.attr(name) !== undefined;
};