$(window).load(function() {
    $('.pop_delete_popsubject').click(function () {
        var confirmbox = confirm('Weet u zeker dat u dit onderwerp wilt verwijderen?');
        if (confirmbox) {
            var url = delete_url_popsubject.replace(':id', $(this).attr('item_id'));
            $('#deleteform_popsubject').attr('action', url);
            $('#deleteform_popsubject').submit();
        }
    });
    $('.pop_delete_popaction').click(function () {
        var confirmbox = confirm('Weet u zeker dat u deze actie wilt verwijderen?');
        if (confirmbox) {
            var url = delete_url_popaction.replace(':id', $(this).attr('item_id'));
            $('#deleteform_popaction').attr('action', url);
            $('#deleteform_popaction').submit();
        }
    });
    $('.pop_delete_popnote').click(function () {
        var confirmbox = confirm('Weet u zeker dat u deze notitie wilt verwijderen?');
        if (confirmbox) {
            var url = delete_url_popnote.replace(':id', $(this).attr('item_id'));
            $('#deleteform_popnote').attr('action', url);
            $('#deleteform_popnote').submit();
        }
    });
    $('.pop_delete_usage').click(function () {
        var confirmbox = confirm('Weet u zeker dat u dit verbruik wilt verwijderen?');
        if (confirmbox) {
            var url = delete_url_usage.replace(':id', $(this).attr('item_id'));
            $('#deleteform_usage').attr('action', url);
            $('#deleteform_usage').submit();
        }
    });
    $('.pop_confirm_evaluation').click(function(e){
        var confirmbox = confirm('Weet u zeker dat u deze evaluatie wilt valideren?');
        if(!confirmbox){
            e.preventDefault();
        }
    });
    $('.pop_delete_pop').click(function(e){
        var confirmbox = confirm('Weet u zeker dat u deze POP wilt verwijderen?');
        if(confirmbox){
            $('#form_delete_pop').submit();
        }
    });
    $('.pop_open_suggestion_content').click(function(){
        if($('#suggestion_content_' + $(this).attr('suggestion_content_key')).is(':visible')){
            $('#suggestion_content_' + $(this).attr('suggestion_content_key')).hide();
            $(this).html('Toon afspraken');
        }
        else{
            $('#suggestion_content_' + $(this).attr('suggestion_content_key')).show();
            $(this).html('Verberg afspraken');
        }
    });
    $('.pop_create_connectedsubject').click(function(){
        $('#form_create_connectedsubject_type').val($(this).attr('connected_type'));
        $('#form_create_connectedsubject_type_id').val($(this).attr('connected_type_id'));
        $('#form_create_connectedsubject').submit();
    });
    $('.pop_delete_connectedsubject').click(function(){
        $('#form_delete_connectedsubject_type').val($(this).attr('connected_type'));
        $('#form_delete_connectedsubject_type_id').val($(this).attr('connected_type_id'));
        $('#form_delete_connectedsubject').submit();
    });
    $('.pop_accept_as_manager').click(function(e){
        var confirmbox = confirm('Weet u zeker dat u begeleider van deze POP wilt zijn');
        if(confirmbox){
            $('#form_accept_as_manager').submit();
        }
    });
    $('.pop_deny_as_manager').click(function(e){
        var confirmbox = confirm('Weet u zeker dat u geen begeleider van deze POP wilt zijn');
        if(confirmbox){
            $('#form_deny_as_manager').submit();
        }
    });
    $('.pop_finish').click(function(){
        var confirmbox = confirm('Weet u zeker dat u deze POP wilt afronden?');
        if(confirmbox){
            $('#form_finish_pop').submit();
        }
    });
    $('.pop_to_concept').click(function(){
        var confirmbox = confirm('Weet u zeker dat u de status van deze POP wilt terugzetten naar concept?');
        if(confirmbox){
            $('#form_to_concept').submit();
        }
    });
});